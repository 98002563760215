import { iTwinErrorI18n } from "./i18n";
/**
 * This will interpret ApolloErrors to generate a readable text message
 * or return the message property of any other object, if present.
 * @param error ApolloError | Error |any
 */
export const getErrorText = (error) => {
    var _a, _b, _c, _d, _e, _f;
    const defaultError = iTwinErrorI18n.t("GraphQl.errors.defaultError");
    if (error) {
        if (((_a = error.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            return error.graphQLErrors[0].message;
        }
        if (((_d = (_c = (_b = error.networkError) === null || _b === void 0 ? void 0 : _b.result) === null || _c === void 0 ? void 0 : _c.errors) === null || _d === void 0 ? void 0 : _d.length) > 0) {
            if (((_f = (_e = error.networkError.result.errors[0]) === null || _e === void 0 ? void 0 : _e.extensions) === null || _f === void 0 ? void 0 : _f.code) === "401") {
                return iTwinErrorI18n.t("GraphQl.errors.unauthorizedError");
            }
            else {
                return error.networkError.result.errors[0].message;
            }
        }
        if (error.message) {
            return error.message;
        }
    }
    return defaultError;
};
